import React from 'react';
import DashboardView from './dashboardview';
import RecommendStage1 from './component/stage1/RecommendStage1';
import { observer } from 'mobx-react';
import { MenuArea } from '../../../components/mainMenu/MenuArea';
import { PlayerProvider } from '../../../hooks/context/PlayerContext';
import BanpickRecordItem from './component/stage3';
import BanpickDetail from './component/stage3/BanPickHistory';

const ProvidedRecommendStage1 = observer(({ vm }: any) => {
  return (
    <PlayerProvider>
      <RecommendStage1 vm={vm} />
    </PlayerProvider>
  );
});

const RecommendArea = observer(({ vm }: any) => {
  return (
    <MenuArea title="Argo Board" selectedItem="Argo Board">
      {vm.overallStep === 0 && <ProvidedRecommendStage1 vm={vm} />}
      {vm.overallStep === 1 && <DashboardView vm={vm} />}
      {vm.overallStep === 2 && <BanpickRecordItem record={vm.historyData} />}
    </MenuArea>
  );
});

export default RecommendArea;
