// WidgetBox.tsx
import React from 'react';
import styled from 'styled-components';
import WidgetMenubar from './WidgetMenubar';
import { observer } from 'mobx-react';
import { WidgetTypes } from '../../type';

const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #14145550;
  width: 100%;
  height: 320px;
  border-radius: 4px;
  border: 1px solid #242424;
`;

interface WidgetBoxProps {
  vm: any;
  type: WidgetTypes | null;
  canModify?: boolean;
  children: React.ReactNode;
  gridColumn?: string; // gridColumn prop 추가
  onParameterClick?: () => void;
}

const WidgetBox = observer(
  ({ vm, type, canModify, children, onParameterClick }: WidgetBoxProps) => {
    return (
      <WidgetContainer>
        <WidgetMenubar
          vm={vm}
          type={type}
          canModify={canModify}
          onParameterClick={onParameterClick}
        />
        {children}
      </WidgetContainer>
    );
  }
);

export default WidgetBox;
