import React from 'react';
import styled from 'styled-components';
import {
  XYChart,
  Axis,
  LineSeries,
  Grid,
  buildChartTheme,
  Tooltip,
  AnimatedAxis,
  AnimatedLineSeries,
  AnimatedGrid,
} from '@visx/xychart';
import { SimulateWinrateState, WinrateState } from '../../type';
import PUpIcon from '../../../../../assets/pointUp.svg';
import PDownIcon from '../../../../../assets/pointDown.svg';
import PositionHeatMap from '../../component/stage2/PositionHeatmap';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

const SimulateMark = styled.div`
  display: flex;
  position: absolute;
  top: 5%;
  right: 5%;
  width: 60px;
  height: 24px;

  background-color: #aadd2280;

  z-index: 5;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #242424;

  justify-content: center;
  align-items: center;
`;

const HoverDetailContainer = styled.div<{
  $isSimulated?: boolean | undefined | 0;
}>`
  position: absolute;
  background-color: #f6f6f6;
  width: 260px;
  height: 118px;

  border-radius: 4px;
  padding: 15px;

  z-index: 10;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;

  border: ${(props) => (props.$isSimulated ? '4px solid #aadd0050' : 'none')};
  box-shadow: ${(props) =>
    props.$isSimulated ? '0px 0px 10px 4px rgba(150, 200, 0, 0.5)' : 'none'};
`;

const HoverDetailImage = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
`;

const PercentPointText = styled.div<{
  $isUp: boolean | null;
}>`
  margin: 8% 0;
  font-family: 'Beaufort for LOL';
  font-weight: 900;
  font-size: 20px;
  font-style: italic;

  color: ${(props) =>
    props.$isUp === null ? '#242424' : props.$isUp ? '#05527b' : '#7b0505'};

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const RateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 10%;
`;

const RateTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
`;

const Rate = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Beaufort for LOL';
  font-style: italic;
  font-weight: 900;
  font-size: 16px;
`;

// 스크롤 가능한 컨테이너 스타일
const ScrollContainer = styled.div`
  width: 750px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7e40ff;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #c7c7c720;
    border-radius: 6px;
  }
`;

const HoverDetail = ({
  data,
  allData,
  isSimulated,
}: {
  data: any;
  allData: WinrateState['data'];
  isSimulated?: boolean | undefined | 0;
}) => {
  const nearestKey = data.turn;
  let percentPoint: number = 0;
  let isUp: boolean | null = null;

  const keyTeamDict = {
    1: 'blue',
    2: 'red',
    3: 'blue',
    4: 'red',
    5: 'blue',
    6: 'red',
    7: 'blue',
    8: 'red',
    9: 'red',
    10: 'blue',
    11: 'blue',
    12: 'red',
    13: 'red',
    14: 'blue',
    15: 'red',
    16: 'blue',
    17: 'red',
    18: 'blue',
    19: 'blue',
    20: 'red',
  };

  const allDataArray = Object.values(allData); // allData를 배열로 변환

  if (nearestKey <= 0) {
    percentPoint = 0;
  } else if (nearestKey < allDataArray.length) {
    // nearestKey가 배열의 길이 이내일 때만 참조
    percentPoint = parseFloat(
      (data.winrate - allDataArray[nearestKey - 1].winrate).toFixed(2)
    );
  } else {
    percentPoint = 0; // 배열 범위를 초과할 경우 기본값 할당
  }

  if (percentPoint < 0) {
    percentPoint = -percentPoint;
    isUp = false;
  } else if (percentPoint === 0) {
    isUp = null;
  } else {
    isUp = true;
  }

  return (
    <HoverDetailContainer $isSimulated={isSimulated}>
      {isSimulated && <SimulateMark>시뮬레이션</SimulateMark>}
      <HoverDetailImage
        src={data.champ.image || null}
        style={{
          filter:
            nearestKey <= 6 || (nearestKey <= 16 && nearestKey >= 13)
              ? 'grayscale(100%)'
              : 'none',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <PercentPointText $isUp={isUp}>
          {percentPoint === 0 ? '- ' : percentPoint}
          <div
            style={{
              fontStyle: 'normal',
            }}
          >
            %p
          </div>
          <span
            style={{
              display: 'inline-block',
              width: '8px',
            }}
          />
          {isUp === null ? (
            <></>
          ) : isUp ? (
            <img src={PUpIcon} alt="up" />
          ) : (
            <img src={PDownIcon} alt="down" />
          )}
        </PercentPointText>
        <PositionHeatMap
          team={keyTeamDict[nearestKey as keyof typeof keyTeamDict]}
          data={data.positionPredict}
        />
        <RateContainer>
          <div>
            <RateTitle>예상 승률</RateTitle>
            <Rate>{data.winrate}%</Rate>
          </div>
          <div
            style={{
              marginLeft: '10px',
            }}
          >
            <RateTitle>예상 픽률</RateTitle>
            <Rate>{data.pickrate}%</Rate>
          </div>
        </RateContainer>
      </div>
    </HoverDetailContainer>
  );
};

// 스타일 정의
const LabelValue = styled.text`
  font-family: 'Beaufort for LOL';
  font-size: 12px;
  font-weight: 900;
  fill: #fafafa;
  text-anchor: middle; /* 텍스트를 중앙 정렬 */
`;

// 사용자 정의 테마
const customTheme = buildChartTheme({
  backgroundColor: 'transparent',
  colors: ['#7e40ff', '#aa4022'],
  xAxisLineStyles: {
    stroke: '#c7c7c720',
  },
  yAxisLineStyles: {
    stroke: '#c7c7c720',
  },
  xTickLineStyles: {
    stroke: '#7e40ff',
  },
  yTickLineStyles: {
    stroke: '#ffffff',
  },
  tickLength: 0,
  gridColor: '#c7c7c720',
  gridColorDark: '#c7c7c720',
  gridStyles: {
    stroke: '#c7c7c720',
    strokeWidth: 0.5,
    strokeDasharray: '1',
  },
});

// LinePlot 컴포넌트
const LinePlot = observer(
  ({
    data,
    simData,
  }: {
    data: WinrateState;
    simData?: SimulateWinrateState;
  }) => {
    let winrateData = data.data;
    const SimulatePoint = Object.keys(winrateData).length - 1;

    winrateData = { ...simData?.data, ...data.data };

    const winrateDataKeys = Object.keys(winrateData);
    const winrateDataValues = Object.values(winrateData);

    // 데이터 기반으로 Y축 범위 계산
    const minYValue =
      Math.min(...winrateDataKeys.map((d: any) => winrateData[d].winrate)) - 1;
    const maxYValue =
      Math.max(...winrateDataKeys.map((d: any) => winrateData[d].winrate)) + 1;

    const chartWidth = Math.max(750, winrateDataValues.length * 50);

    return (
      <ScrollContainer>
        <XYChart
          width={chartWidth}
          height={250}
          xScale={{ type: 'band', paddingInner: 0.3 }}
          yScale={{
            type: 'log',
            includeZero: false,
            domain: [minYValue, maxYValue],
          }}
          theme={customTheme}
          margin={{ top: 20, bottom: 60, left: 40, right: 20 }}
        >
          <AnimatedGrid
            rows={true}
            columns={true}
            lineStyle={{ stroke: '#c7c7c720' }}
            numTicks={winrateDataValues.length}
          />
          <AnimatedAxis
            orientation="bottom"
            numTicks={winrateDataValues.length}
            tickComponent={({
              x,
              y,
              formattedValue,
            }: {
              x: number;
              y: number;
              formattedValue: any;
            }) => {
              if (!formattedValue || !winrateData[formattedValue]) return null;

              const champData = winrateData[formattedValue];

              return (
                <g>
                  {/* 챔피언 이미지를 SVG 이미지로 렌더링 */}
                  <circle
                    cx="-2" // 원의 중심 위치를 조정하세요
                    cy={
                      [0, 1, 2, 3, 4, 5, 12, 13, 14, 15].includes(
                        parseInt(formattedValue) - 1
                      )
                        ? '4'
                        : '5'
                    }
                    r={
                      [0, 1, 2, 3, 4, 5, 12, 13, 14, 15].includes(
                        parseInt(formattedValue) - 1
                      )
                        ? '6'
                        : '12'
                    }
                    fill="none"
                    stroke={
                      parseInt(formattedValue) === 0
                        ? 'none'
                        : [0, 2, 4, 6, 9, 10, 13, 15, 17, 18].includes(
                            parseInt(formattedValue) - 1
                          )
                        ? '#0060ff'
                        : '#ff4000'
                    }
                    strokeWidth={
                      [0, 1, 2, 3, 4, 5, 12, 13, 14, 15].includes(
                        parseInt(formattedValue) - 1
                      )
                        ? '0.5'
                        : '2'
                    }
                  />
                  <image
                    href={champData.champ.image}
                    x={
                      [0, 1, 2, 3, 4, 5, 12, 13, 14, 15].includes(
                        parseInt(formattedValue) - 1
                      )
                        ? '-6'
                        : '-12'
                    }
                    y={
                      [0, 1, 2, 3, 4, 5, 12, 13, 14, 15].includes(
                        parseInt(formattedValue) - 1
                      )
                        ? '-1'
                        : '-5'
                    }
                    width={
                      [0, 1, 2, 3, 4, 5, 12, 13, 14, 15].includes(
                        parseInt(formattedValue) - 1
                      )
                        ? '10'
                        : '20'
                    }
                    height={
                      [0, 1, 2, 3, 4, 5, 12, 13, 14, 15].includes(
                        parseInt(formattedValue) - 1
                      )
                        ? '10'
                        : '20'
                    }
                    clipPath="circle(50%)"
                    style={{
                      filter:
                        parseInt(formattedValue) <= 6 ||
                        (parseInt(formattedValue) <= 16 &&
                          parseInt(formattedValue) >= 13)
                          ? 'grayscale(100%)'
                          : 'none',
                    }}
                  />
                  {/* 승률 및 픽률을 SVG 텍스트로 렌더링 */}
                  <LabelValue x="0" y="32">
                    {champData.winrate.toString() + '%'}
                  </LabelValue>
                  {champData.pickorder === 0 ? (
                    <></>
                  ) : (
                    <LabelValue x="0" y="48">
                      {champData.pickorder.toString() +
                        (champData.pickorder % 10 === 1
                          ? 'st'
                          : champData.pickorder % 10 === 2
                          ? 'nd'
                          : champData.pickorder % 10 === 3
                          ? 'rd'
                          : 'th')}
                    </LabelValue>
                  )}
                </g>
              );
            }}
          />
          <AnimatedAxis orientation="left" />
          <Tooltip
            showDatumGlyph
            snapTooltipToDatumX
            renderTooltip={({ tooltipData }) => {
              const nearestDatum = tooltipData?.nearestDatum?.datum;
              if (!nearestDatum) return null;

              // 인덱스 조정을 위해 두 개의 시리즈 데이터를 합쳐서 계산
              const combinedIndex =
                (tooltipData?.nearestDatum?.index ?? 0) +
                (tooltipData?.nearestDatum?.key === 'dashedLine'
                  ? winrateDataValues.slice(0, SimulatePoint + 1).length
                  : 0);

              return (
                <HoverDetail
                  data={nearestDatum}
                  allData={data.data}
                  isSimulated={combinedIndex - 1 > SimulatePoint}
                />
              );
            }}
          />
          <AnimatedLineSeries
            dataKey="solidLine"
            data={winrateDataValues.slice(0, SimulatePoint + 1)}
            xAccessor={(d: any) => d.turn}
            yAccessor={(d: any) => d.winrate}
          />
          <AnimatedLineSeries
            dataKey="dashedLine"
            data={winrateDataValues.slice(
              SimulatePoint,
              winrateDataValues.length
            )}
            xAccessor={(d: any) => d.turn}
            yAccessor={(d: any) => d.winrate}
            colorAccessor={() => '#aaaa22'}
            strokeDasharray="4,4"
          />
        </XYChart>
      </ScrollContainer>
    );
  }
);

export default LinePlot;
