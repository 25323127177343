import styled from 'styled-components';
import { useAuth } from '../../../hooks/context/AuthContext';

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center; /* 수직 정렬 */
  padding: 1rem 0rem;
`;

const EmailTitle = styled.p`
  text-align: left;
  font-weight: 300;
  font-size: 16px;
  color: #424242;
  margin: 0;
`;

const Email = styled.p`
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  color: #121212;
  margin: 0;
`;

const SubTitle = styled.h2`
  margin-bottom: 0.5rem;
  text-align: left;
  font-weight: 600;
  font-size: 32px;
  color: #121212;
  margin: 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ProfileArea = () => {
  const { imageURL, myName, loginEmail } = useAuth();
  return (
    <ProfileContainer>
      <ProfileImage
        src={imageURL === null ? '/profile.png' : imageURL}
        alt="Profile"
      />
      <TextContainer>
        <SubTitle>{myName}</SubTitle>
        <EmailTitle>이메일 주소</EmailTitle>
        <Email>{loginEmail}</Email>
      </TextContainer>
    </ProfileContainer>
  );
};
