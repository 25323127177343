import React from 'react';
import styled from 'styled-components';
import moreIcon from '../../../../../assets/more.svg';
import pinIcon from '../../../../../assets/pin.svg';
import parameterIcon from '../../../../../assets/parameter.svg';
import { observer } from 'mobx-react';
import { WidgetTypes } from '../../type';

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 134px;
  height: 32px;

  background: rgba(126, 64, 255, 0.5);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  text-align: center;

  color: #fafafa;
  cursor: default;
`;

const WidgetMenubarContainer = styled.div`
  width: 100%;
  padding: 10px;
  gap: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-right: 25px;
`;

interface WidgetMenubarProps {
  vm: any;
  type: WidgetTypes | null;
  canModify?: boolean;
  onParameterClick?: () => void;
}

const WidgetMenubar = observer(
  ({ vm, type, canModify, onParameterClick }: WidgetMenubarProps) => {
    const titleName: { [key in WidgetTypes]: string } = {
      draftboard: '드래프트 보드',
      winrate: '예상 승률',
      chooseChamps: '챔피언 선택',
      recommendChamps: '챔피언 추천',
      simulate: '시뮬레이션',
    };

    return (
      <WidgetMenubarContainer>
        <Title>{type && titleName[type]}</Title>
        <ButtonsContainer>
          <img
            style={{
              cursor: 'pointer',
              display: canModify ? 'block' : 'none',
            }}
            src={parameterIcon}
            alt="parameter"
            width={20}
            height={20}
            onClick={onParameterClick} // 클릭 시 콜백 함수 호출
          />
          {/* 다른 아이콘들 */}
        </ButtonsContainer>
      </WidgetMenubarContainer>
    );
  }
);

export default WidgetMenubar;
