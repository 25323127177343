import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LinePlot from '../../../inference/recommend/widgets/components/WinrateGraphs';
import { NewHistoryRecord } from '../../../inference/recommend/type';
import {
  DetailTable,
  TeamMetaChart,
} from '../../../inference/recommend/component/stage3/MetadataTable';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { LoadingSpinner } from '../../../inference/recommend/component/stage2/loadingSpinner';
import { getHistory } from '../../../../apis/historyApis';

const RecordContainer = styled.div<{ showDetails?: boolean }>`
  display: flex;
  flex-direction: column;

  border: 1px solid #c7c7c7;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  margin-bottom: 1rem;
  padding: 20px 40px;
  transition: max-height 0.3s ease-in-out, padding 3s ease-in-out;
  overflow: hidden;

  width: 90%;
`;

const TeamContainer = styled.div<{ showDetails?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const ChampionImage = styled.img`
  width: 100%;
  height: auto;
`;

const TeamSection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  justify-content: space-between;
  align-items: center;
`;

const DateText = styled.div`
  font-size: 1.2rem;
  color: #888;
`;

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  flex-grow: 1;
  max-width: calc(20%);
`;

const TitleText = styled.div`
  font-size: 1.7rem;
  font-weight: bold;
  padding: 0rem 1rem;
  border-right: 1px solid #888;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  gap: 10px;
  margin-bottom: 1rem;
`;

const PlayerCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const PlayerImage = styled.div<{ url: string }>`
  width: 50%;
  padding-bottom: 50%;
  border-radius: 50%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const PlayerName = styled.div`
  font-family: 'Pretendard';
  font-size: 1rem;
  font-weight: 200;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 300px) {
    font-size: 0.8rem;
  }
  @media (max-width: 200px) {
    font-size: 0.6rem;
  }
`;
const BanDeatilSection = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;

  margin: 1rem 0;
  border-radius: 10px;
  align-self: center;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #060611;

  gap: 35px;
  padding: 1.5rem;
  box-sizing: border-box;
`;
const BorderDiv = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 2.5px;
  background: rgba(0, 0, 0, 0.1);
  border: 0.1px solid #ffffff;
`;

const WindRatesContainer = styled.div`
  display: flex;
  width: 30vw; /* 반응형 너비 설정 */
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
`;

const CenterVersusText = styled.div`
  font-size: 1.7vw;
  font-weight: bold;
  margin: 0 0.5rem;
  color: black;
`;

const WinRate = styled.div<{ win?: boolean }>`
  font-family: 'Beaufort for LOL';
  font-style: normal;
  font-size: 2vw;
  font-weight: 900;
  color: ${({ win }) => (win ? '#00891E' : '#AF2A00')};
`;

interface BanpickRecordProps {
  record?: NewHistoryRecord;
}

const ScrollBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
`;

const BanpickRecordItem: React.FC<BanpickRecordProps> = () => {
  const [recordLists, setRecordLists] = useState<NewHistoryRecord[]>();

  useEffect(() => {
    getHistory({}).then((res) => {
      setRecordLists(res.data);
    });
  }, []);

  return (
    <ScrollBox>
      {recordLists ? (
        recordLists?.map((recordblob: any, index: number) => {
          const record = recordblob.result;

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <RecordContainer showDetails={true}>
                <TitleContainer>
                  <TitleText>Argo Result</TitleText>
                  <DateText>{record?.date}</DateText>
                </TitleContainer>
                <TeamContainer showDetails={true}>
                  <TeamSection>
                    {record
                      ?.players!.slice(0, 5)
                      .map((player: any, index: number) => (
                        <PlayerContainer key={index}>
                          <PlayerCard>
                            <PlayerImage
                              url={player.championImage || '/pProfile.png'}
                            />
                            <PlayerName>{player.name}</PlayerName>
                          </PlayerCard>
                        </PlayerContainer>
                      ))}
                  </TeamSection>

                  <CenterVersusText>vs</CenterVersusText>
                  <TeamSection>
                    {record
                      ?.players!.slice(5, 10)
                      .map((player: any, index: number) => (
                        <PlayerContainer key={index}>
                          <PlayerCard>
                            <PlayerImage
                              url={player.championImage || '/pProfile.png'}
                            />
                            <PlayerName>{player.name}</PlayerName>
                          </PlayerCard>
                        </PlayerContainer>
                      ))}
                  </TeamSection>
                </TeamContainer>
                <BorderDiv />
                <BanDeatilSection>
                  {record?.metadata || record?.teamMetadata ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '3rem',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          gap: '2rem',
                        }}
                      >
                        <LinePlot data={{ data: record?.graphData }} />
                        <TeamMetaChart data={record?.teamMetadata} />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          gap: '2rem',
                        }}
                      >
                        <DetailTable
                          champions={record?.players}
                          data={record?.metadata}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: '50vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <LoadingSpinner width={80} />
                    </div>
                  )}
                </BanDeatilSection>
              </RecordContainer>
            </div>
          );
        })
      ) : (
        <LoadingSpinner width={80} />
      )}
    </ScrollBox>
  );
};
export default BanpickRecordItem;
