import { MenuArea } from '../../components/mainMenu/MenuArea';
import HomeArea from './HomeArea';
import { UserDataProvider } from '../../hooks/context/UserDataContext';
const Home = () => {
  return (
    <MenuArea title="콘솔 홈" selectedItem="홈" initialOpen={true}>
      <UserDataProvider>
        <HomeArea />
      </UserDataProvider>
    </MenuArea>
  );
};

export default Home;
