import React from 'react';
import styled, { keyframes } from 'styled-components';

// 회전 애니메이션 정의
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일 정의
const Spinner = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  height: ${({ width }) => width}px;
  border: 2px solid rgba(255, 255, 255, 0.3); // 투명한 흰색
  border-top: 2px solid rgba(255, 100, 128, 0.3); // 흰색 강조 부분
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

// 컨테이너 정의
const Container = styled.div<{ width: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingSpinner = ({ width }: { width: number }) => {
  return (
    <Container width={width}>
      <Spinner width={width} />
    </Container>
  );
};
