import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  ReactNode,
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as authApis from '../../apis/authApis';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

interface AuthContextType {
  user: any;
  loginEmail: string;
  loginPassword?: string;
  loginConfirmPassword?: string;
  signUpEmail: string;
  signUpPassword?: string;
  signUpConfirmPassword?: string;
  signUpPhoneNumber: string; // 기본값이 빈 문자열로 설정됨
  signUpName?: string;
  verifyCode: string; // 기본값이 빈 문자열로 설정됨
  changePassword: string;
  changePasswordConfirm: string;
  step: number;
  history: number[];
  imageURL: string;
  myName: string;
  notiStatus: string;
  notiText: string;
  notiDisplay: boolean;
  isLoading: boolean;
  setStep: (step: number) => void;
  goBack: () => void;
  setLoginEmail: (email: string) => void;
  setLoginPassword: (password: string) => void;
  setLoginConfirmPassword: (password: string) => void;
  setSignUpEmail: (email: string) => void;
  setSignUpPassword: (password: string) => void;
  setSignUpConfirmPassword: (password: string) => void;
  setSignUpPhoneNumber: (phoneNumber: string) => void;
  setSignUpName: (name: string) => void;
  setVerifyCode: (code: string) => void;
  setChangePassword: (password: string) => void;
  setChangePasswordConfirm: (password: string) => void;
  register: () => void;
  clickEmailSumbit: () => void;
  clickLoginSubmit: () => void;
  setNoti: (status: string, text: string, display: boolean) => void;
  clickLogOut: () => void;
  changePasswordLink: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface Props {
  children: ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  const [step, setStepState] = useState(1);
  const [history, setHistory] = useState<number[]>([]);
  const [loginEmail, setLoginEmailState] = useState<string>('');
  const [loginPassword, setLoginPasswordState] = useState<string | undefined>(
    undefined
  );
  const [loginConfirmPassword, setLoginConfirmPasswordState] = useState<
    string | undefined
  >(undefined);
  const [signUpEmail, setSignUpEmailState] = useState<string>('');
  const [signUpPassword, setSignUpPasswordState] = useState<string | undefined>(
    undefined
  );
  const [signUpConfirmPassword, setSignUpConfirmPasswordState] = useState<
    string | undefined
  >(undefined);
  const [signUpPhoneNumber, setSignUpPhoneNumberState] = useState<string>('');
  const [signUpName, setSignUpNameState] = useState<string | undefined>(
    undefined
  );
  const [verifyCode, setVerifyCodeState] = useState<string>('');
  const [changePassword, setChangePasswordState] = useState<string>('');
  const [changePasswordConfirm, setChangePasswordConfirmState] =
    useState<string>('');
  const [imageURL, setImageURL] = useState<string>('');
  const [myName, setMyName] = useState<string>('');
  const [notiStatus, setNotiStatus] = useState<string>('success');
  const [notiText, setNotiText] = useState<string>('');
  const [notiDisplay, setNotiDisplay] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const setNoti = (status: string, text: string, display: boolean) => {
    setNotiStatus(status);
    setNotiText(text);
    setNotiDisplay(display);
  };

  const setStep = (newStep: number) => {
    setHistory((prevHistory) => [...prevHistory, step]);
    setNoti('', '', false);
    setStepState(newStep);
  };

  const goBack = () => {
    setNoti('', '', false);
    setHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      const previousStep = newHistory.pop();
      if (previousStep !== undefined) {
        setStepState(previousStep);
      }
      return newHistory;
    });
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (notiDisplay) {
      timeout = setTimeout(() => {
        setNotiDisplay(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [notiDisplay]);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      try {
        const decoded = jwtDecode<any>(token);
        const currentTime = Math.floor(Date.now() / 1000); // 현재 시간 (초 단위)

        if (decoded.exp < currentTime) {
          setUser(null);
          localStorage.removeItem('accessToken');
        } else {
          setUser(decoded);
        }
      } catch (error) {
        setUser(null);
      }
    } else {
      setUser(null);
    }
    setIsLoading(false);
  }, []);

  const setLoginEmail = (email: string) => setLoginEmailState(email);
  const setLoginPassword = (password: string) =>
    setLoginPasswordState(password);
  const setLoginConfirmPassword = (password: string) =>
    setLoginConfirmPasswordState(password);
  const setSignUpEmail = (email: string) => setSignUpEmailState(email);
  const setSignUpPassword = (password: string) =>
    setSignUpPasswordState(password);
  const setSignUpConfirmPassword = (password: string) =>
    setSignUpConfirmPasswordState(password);
  const setSignUpPhoneNumber = (phoneNumber: string) =>
    setSignUpPhoneNumberState(phoneNumber);
  const setSignUpName = (name: string) => setSignUpNameState(name);
  const setVerifyCode = (code: string) => setVerifyCodeState(code);
  const setChangePassword = (password: string) =>
    setChangePasswordState(password);
  const setChangePasswordConfirm = (password: string) =>
    setChangePasswordConfirmState(password);

  const clickEmailSumbit = async () => {
    setNoti('loading', '', true);
    await authApis
      .emailSubmit({
        email: loginEmail,
      })
      .then((response) => {
        if (response.code === 200) {
          setStep(2);
          setImageURL(response.data.imageURL);
          setMyName(response.data.name);
          setNoti('success', '', false);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || '네트워크 연결 오류';
        setNoti('error', errorMessage, true);
      });
  };

  const clickLoginSubmit = async () => {
    setNoti('loading', '', true);
    await authApis
      .login({
        email: loginEmail,
        password: loginPassword || '',
      })
      .then((response) => {
        if (response.code === 200) {
          setNoti('success', response.message, true);
          const { accessToken, refreshToken } = response;
          Cookies.set('refreshToken', refreshToken, {
            secure: true,
            sameSite: 'Strict',
          });
          localStorage.setItem('accessToken', accessToken);
          const decoded = jwtDecode<any>(accessToken);
          setUser(decoded);
          console.log(decoded);
          navigate('/home');
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || '네트워크 연결 오류';
        setNoti('error', errorMessage, true);
      });
  };

  const clickLogOut = () => {
    localStorage.removeItem('accessToken');
    Cookies.remove('refreshToken');
    setStep(1);
    setUser(null);
    setLoginPassword('');
    setNoti('success', '성공적으로 로그아웃 되었습니다.', true);

    navigate('/auth');
  };

  const register = async () => {
    setNoti('loading', '', true);

    await authApis
      .register({
        email: signUpEmail,
        password: signUpPassword || '',
        call: signUpPhoneNumber,
        name: signUpName || '',
      })
      .then((response) => {
        if (response.code === 200) {
          setStep(4);
          setNoti('success', response.message, true);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || '네트워크 연결 오류';
        setNoti('error', errorMessage, true);
      });
  };

  const changePasswordLink = async () => {
    await authApis
      .forgotPassword({ email: loginEmail })
      .then((response) => {
        if (response.code === 200) {
          setStep(5);
          setNoti('success', response.message, true);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || '네트워크 연결 오류';
        setNoti('error', errorMessage, true);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loginEmail,
        loginPassword,
        loginConfirmPassword,
        signUpEmail,
        signUpPassword,
        signUpConfirmPassword,
        signUpPhoneNumber,
        signUpName,
        verifyCode,
        changePassword,
        changePasswordConfirm,
        step,
        history,
        imageURL,
        myName,
        notiStatus,
        notiText,
        notiDisplay,
        isLoading,
        setStep,
        goBack,
        setLoginEmail,
        setLoginPassword,
        setLoginConfirmPassword,
        setSignUpEmail,
        setSignUpPassword,
        setSignUpConfirmPassword,
        setSignUpPhoneNumber,
        setSignUpName,
        setVerifyCode,
        setChangePassword,
        setChangePasswordConfirm,
        clickEmailSumbit,
        clickLoginSubmit,
        register,
        setNoti,
        clickLogOut,
        changePasswordLink,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
