import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import VerticalLineIcon from '../../../../../assets/verticalLine.svg';
import { observer } from 'mobx-react';

const RowFlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;

  box-sizing: border-box;
`;

const ChooseMyteam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-family: 'Pretendard';
`;

const ChooseTitle = styled.div`
  background: #000000;
  border-radius: 8px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 900;
  font-size: 22px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: #ffffff;

  width: 296px;
  height: 68px;
`;

const TeamButton = styled.div<{
  $team: string;
  $active: boolean;
}>`
  width: 141px;
  height: 68px;
  border-radius: 8px;

  background: ${(props) =>
    props.$team === 'blue' && props.$active
      ? '#79dfff'
      : props.$team === 'blue' && !props.$active
      ? '#79dfff20'
      : props.$team === 'red' && props.$active
      ? '#ff7f7f'
      : '#ff7f7f20'};

  color: ${(props) =>
    props.$team === 'blue' && props.$active
      ? '#001168'
      : props.$team === 'blue' && !props.$active
      ? '#00116820'
      : props.$team === 'red' && props.$active
      ? '#a80000'
      : '#a8000020'};

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;

  cursor: pointer;
`;

const WinrateContainer = styled.div`
  position: relative;
  width: 400px;
`;

const WinrateTitle = styled.div`
  width: 100%;
  height: 68px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;

  text-align: center;
  color: #00000050;

  positive: absolute;
  top: 10%;
  left: 10%;
`;

const WinrateContext = styled.div`
  width: 100%;
  height: 68px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 900;
  font-size: 64px;

  text-align: center;
  color: #000000cc;
`;

const StartDraftButton = styled.div<{
  $isActivated: boolean;
}>`
  width: 262px;
  height: 68px;
  background: ${(props) => (props.$isActivated ? '#00de9bcc' : '#00de9b35')};
  border-radius: 10px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: ${(props) => (props.$isActivated ? '#fafafa' : '#aaaaaa90')};
  cursor: ${(props) => (props.$isActivated ? 'pointer' : 'default')};
`;

const SelectTeam = observer(({ vm }: { vm: any }) => {
  const [animatedValue, setAnimatedValue] = useState(0);
  const intervalId = useRef<NodeJS.Timeout | null>(null); // useRef로 intervalId 선언

  useEffect(() => {
    if (vm.isLoadingWinrate) {
      intervalId.current = setInterval(() => {
        setAnimatedValue(Math.floor(Math.random() * 100));
      }, 100);
    } else {
      setAnimatedValue(vm.winrateState.data[0]['winrate']);
    }

    return () => {
      if (intervalId.current) clearInterval(intervalId.current); // intervalId가 있을 때만 clearInterval 호출
    };
  }, [vm.isLoadingWinrate, vm.winrateState.data]);

  return (
    <RowFlexDiv>
      <ChooseMyteam>
        <ChooseTitle>나의 팀 선택:</ChooseTitle>
        <RowFlexDiv>
          <TeamButton
            $team="blue"
            $active={vm.selectedTeam === 'blue'}
            onClick={() => {
              vm.setSelectedTeam('blue');
            }}
          >
            Blue
          </TeamButton>
          <span style={{ width: '10px' }} />
          <TeamButton
            $team="red"
            $active={vm.selectedTeam === 'red'}
            onClick={() => {
              vm.setSelectedTeam('red');
            }}
          >
            Red
          </TeamButton>
        </RowFlexDiv>
      </ChooseMyteam>
      <img src={VerticalLineIcon} alt="vertical-line" />
      <WinrateContainer>
        <WinrateTitle>예상 승률</WinrateTitle>
        <WinrateContext>{animatedValue}%</WinrateContext>
      </WinrateContainer>
      <img src={VerticalLineIcon} alt="vertical-line" />
      <StartDraftButton
        $isActivated={vm.isActivateStep2}
        onClick={() => {
          vm.isActivateStep2 && vm.setOverallStep(1);
        }}
      >
        Draft 시작하기
      </StartDraftButton>
    </RowFlexDiv>
  );
});

export default SelectTeam;
