import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useRoaster } from '../../../hooks/context/RoasterContext';
import { RoasterPlayer } from './type';
import { RoasterContainer } from '../../../components/RoasterContainer';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { SearchPlayer } from '../../../components/search/SearchPlayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePlayer } from '../../../hooks/context/PlayerContext';
import Spinner from '../../../components/common/loading';
import CenterNotification from '../../../components/common/centerNotification';

const RoastersContainer = styled.div<{ containerHeight?: string }>`
  width: 100%;
  max-width: 900px;
  align-self: center;
  padding: 0.7rem 0.5rem;
  background: white;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(800px, 800px));
  gap: 10px;
  height: ${({ containerHeight }) => containerHeight};
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);
  justify-content: center; /* Center items within the container */
  /* Scrollbar styles */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
    height: 20%;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const PlusButton = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 0px 4px 0.5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 100%;
  min-height: 120px;
  max-height: 120px;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 36px;
  color: #666;
  &:hover {
    filter: brightness(0.9);
  }
`;

const BackgroundDiv = styled.div`
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  font-family: 'Pretendard';
  font-style: normal;
  width: 100%;
  gap: 3rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  gap: 40px;
  flex: 1;
  overflow: hidden;
`;

const SearchSection = styled.div`
  width: 100%;
  max-width: 800px;
  align-self: center;
  flex: 1;
  overflow: hidden; /* Prevent overflow on the search section */
  box-sizing: border-box;
`;

const RoasterArea = () => {
  const {
    isAllRoasterLoading,
    setIsAllRoasterLoading,
    isRoasterLoading,
    setIsRoasterLoading,
    roasterDataList,
    setRoasterDataList,
    nowRoasterPosition,
    setNowRoasterPosition,
    roasterChange,
    addRoaster,
    noti,
    setNoti,
  } = useRoaster();
  const { filteredPlayers, isLoading } = usePlayer();
  const [sectionHeight, setSectionHeight] = useState(0);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentSection = sectionRef.current;

    if (sectionRef.current) {
      setSectionHeight((sectionRef.current.clientHeight - 80) / 2); // Split the height in half
    }

    const handleResize = () => {
      if (sectionRef.current) {
        setSectionHeight((sectionRef.current.clientHeight - 80) / 2); // Split the height in half
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleRoasterPlayerChange = (
    roasterIndex: number,
    position: string
  ) => {
    console.log('roasterIndex', roasterIndex);
    setNowRoasterPosition({ position, roasterIndex });
  };

  const handlePlayerClick = (player: RoasterPlayer) => {
    roasterChange(player);
  };

  return (
    <BackgroundDiv ref={sectionRef}>
      <Container>
        <CenterNotification noti={noti} setNoti={setNoti} />
        <RoastersContainer style={{ height: `${sectionHeight}px` }}>
          {isAllRoasterLoading ? (
            <Spinner />
          ) : (
            [
              ...roasterDataList.map((roasterData, index) => (
                <RoasterContainer
                  key={index}
                  roasterData={roasterData}
                  onRoasterPlayerChange={handleRoasterPlayerChange}
                  index={index}
                />
              )),
              <PlusButton onClick={addRoaster}>
                <Icon icon={faCirclePlus} color="black" />
              </PlusButton>,
            ]
          )}
        </RoastersContainer>
        <SearchSection style={{ height: `${sectionHeight}px` }}>
          <SearchPlayer
            onPlayerClick={handlePlayerClick}
            containerHeight={`${sectionHeight}px`}
            isLoading={isLoading}
            filteredPlayers={filteredPlayers}
          />
        </SearchSection>
      </Container>
    </BackgroundDiv>
  );
};

export default RoasterArea;
