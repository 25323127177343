import React, {
  FC,
  useState,
  useEffect,
  ReactNode,
  Children,
  isValidElement,
} from 'react';
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { observer } from 'mobx-react';

// Widget 아이템 타입 정의
interface WidgetProps {
  id: number;
  children: ReactNode; // children으로 위젯을 받음
}

// DashboardProps 정의
interface DashboardProps {
  children?: ReactNode; // children으로 전달된 위젯을 받음
}

// SortableItem 컴포넌트: 개별 드래그 가능한 아이템
const SortableItem: FC<{
  id: number;
  children: ReactNode;
  gridColumn?: string;
}> = ({ id, children, gridColumn }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: 'relative',
    gridColumn: gridColumn || 'auto', // gridColumn 적용
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      {children}
      <div
        {...listeners}
        style={{
          cursor: 'grab',
          padding: '0px',
          position: 'absolute',
          top: '0px',
          left: '35%',
          width: '30%',
          height: '30px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '0px 0px 4px 4px',
        }}
      />
    </div>
  );
};

interface WidgetData {
  id: number;
  element: ReactNode;
  gridColumn?: string;
}

// Dashboard 컴포넌트
const Dashboard: FC<DashboardProps> = ({ children }) => {
  // children에서 위젯을 추출하고, 고유 id를 부여
  const initialWidgets: WidgetData[] =
    Children.map(children, (child, index) => {
      if (isValidElement(child)) {
        const gridColumn = child.props.gridColumn;
        return { id: index + 1, element: child, gridColumn };
      }
      return null;
    })?.filter(Boolean) || [];

  const [widgets, setWidgets] = useState(initialWidgets);
  useEffect(() => {
    const newWidgets: WidgetData[] =
      Children.map(children, (child, index) => {
        if (isValidElement(child)) {
          const gridColumn = child.props.gridColumn;
          return { id: index + 1, element: child, gridColumn };
        }
        return null;
      })?.filter(Boolean) || [];
    setWidgets(newWidgets);
  }, [children]); // 의존성 배열에 children 추가

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setWidgets((items) => {
        const oldIndex = items.findIndex((widget) => widget.id === active.id);
        const newIndex = items.findIndex((widget) => widget.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={widgets.map((widget) => widget.id)}
        strategy={rectSortingStrategy}
      >
        <div className="dashboard-grid" style={gridStyle}>
          {widgets.map((widget) => (
            <SortableItem
              key={widget.id}
              id={widget.id}
              gridColumn={widget.gridColumn}
            >
              {widget.element}
            </SortableItem>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

// 스타일 정의
const gridStyle: React.CSSProperties = {
  display: 'grid',
  padding: '10px 30px 30px 30px',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: '30px',
  overflow: 'hidden', // 오버플로우를 숨김
  maxWidth: '100%', // 최대 크기 제한
  maxHeight: '100%', // 최대 크기 제한
};

export default Dashboard;
