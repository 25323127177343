import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/view';
import Auth from './pages/auth/view';
import UserDataPlayer from './pages/userdata/player/view';
import UserDataPrivate from './pages/userdata/private/view';
import UserDataRoaster from './pages/userdata/roaster/view';
import { AuthProvider } from './hooks/context/AuthContext';
import ProtectedRoute from './routes/ProtectedRoutes';
import NoRoutes from './pages/error/noRoutes';
import MainFuncView from './pages/inference/recommend/view';
import UserDataHistory from './pages/userdata/history/view';
import { ScreenWidthProvider } from './ScreenWidthContext';

function App() {
  return (
    <Router>
      <AuthProvider>
        <ScreenWidthProvider>
          <div className="App">
            <Routes>
              <Route
                path="/home"
                element={<ProtectedRoute element={<Home />} />}
              />
              <Route path="/auth" element={<Auth />} />
              <Route
                path="/argoboard"
                element={<ProtectedRoute element={<MainFuncView />} />}
              />
              <Route
                path="/userdata/history"
                element={<ProtectedRoute element={<UserDataHistory />} />}
              />
              <Route
                path="/userdata/player"
                element={<ProtectedRoute element={<UserDataPlayer />} />}
              />
              <Route
                path="/userdata/private"
                element={<ProtectedRoute element={<UserDataPrivate />} />}
              />
              <Route
                path="/userdata/roaster"
                element={<ProtectedRoute element={<UserDataRoaster />} />}
              />
              <Route path="*" element={<NoRoutes />} />
            </Routes>
          </div>
        </ScreenWidthProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
