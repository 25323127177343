import React from 'react';
import styled from 'styled-components';
import WidgetBox from '../component/stage2/WidgetContainer';
import SearchChampion from '../../../../components/search/SearchChampion';
import { observer } from 'mobx-react';

const Container = styled.div<{
  $isActivated?: boolean;
}>`
  width: 20%;
  height: 100%;
  background-color: ${(props) =>
    props.$isActivated ? 'rgba(126, 64, 255, 0.25)' : 'rgba(126, 64, 255, 1)'};
  border-radius: 8px;
  padding: 10px;

  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.$isActivated ? '#727272' : '#fafafa')};

  gap: 20px;
  cursor: ${(props) => (props.$isActivated ? 'default' : 'pointer')};
`;

const SelectedImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #fafafa;
`;

const Text = styled.div`
  text-align: center;
  font-family: 'Pretendard';
  font-size: 14px;
  font-weight: 700;

  white-space: pre-wrap;
`;

const ChampChooseWidget = observer(({ vm }: any) => {
  return (
    <WidgetBox vm={vm} type="chooseChamps">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '20px',
          height: '100%',
          flexDirection: 'row',
          gap: '3%',
        }}
      >
        <SearchChampion
          containerHeight="230px"
          onChampionClick={vm.setSelectedChamp}
          cannotPickChampions={Object.values(
            vm.draftboardState.champTemplateBasic
          )}
          isPickCompleted={vm.isPickCompleted}
        />
        <Container
          $isActivated={
            vm.parentState?.selectedChamp?.korName !== undefined ? false : true
          }
          onClick={() => {
            vm.parentState?.selectedChamp?.korName && vm.nextTurn();
          }}
        >
          {vm.chooseChampsState?.selectedChamp?.image && (
            <SelectedImage src={vm.chooseChampsState?.selectedChamp?.image} />
          )}
          <Text>{`${
            vm.parentState?.selectedChamp?.korName
              ? vm.parentState?.selectedChamp?.korName + '\n선택하기'
              : '챔피언을\n선택해주세요'
          }`}</Text>
        </Container>
      </div>
    </WidgetBox>
  );
});

export default ChampChooseWidget;
